<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="20" :offset="4">
    <custom-header :title="'Харилцагч хариуцсан менежер засах'"/>
    <el-form label-position="top" :model="customerData" :rules="customerRules" ref="customerData" @submit.native.prevent="getUpdateEmployee('customerData')" >
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Нэр:" prop="name">
                      <el-input v-model="customerData.name" placeholder="Dulmaaaaaa"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="Албан тушаал:" prop="major">
                        <el-input v-model="customerData.major" placeholder="developer"></el-input>
                      </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Е-мэйл хаяг:" prop="email">
                        <el-input v-model="customerData.email" placeholder="bat@gmail.com"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Утас дугаар:" prop="phone">
                      <el-input v-model="customerData.phone" placeholder="999999999"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Ажлын хаяг:" prop="address">
                      <el-input v-model="customerData.address" placeholder="ХУД-3р хороо"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Төлөв" prop="is_active">
                      <el-switch
                        style="display: block"
                        v-model="customerData.is_active"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Идэвхитэй"
                        inactive-text="Идэвхгүй"
                        class="mt10 text-center">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="20">
        <el-col :span="12">
          <div class="text-left border-top pt20">
           <el-button type="danger" @click="confirmLeaveFormDelete = true">Устгах</el-button>
       </div>
      </el-col>
        <el-col :span="4">
        <div class="text-right border-top pt20">
      <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
      <el-button type="success" @click="getUpdateEmployee('customerData')">Хадгалах</el-button>
    </div>
    </el-col>
    </el-row>
       <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/customer-manager-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
      title="Харилцагч устгах"
      :visible.sync="confirmLeaveFormDelete"
      width="50%"
    >
      <div>
        <strong>{{customerData.name}}</strong> Хэрэглэгчийг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveFormDelete = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteEmployee(customerData.employee_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
export default {
  components: {
    customHeader: customHeader
  },
  created () {
    if (this.$route.params.employee_id) {
      this.getOneEmployee(this.$route.params.employee_id)
    }
  },
  data () {
    return {
      confirmLeaveFormDelete: false,
      confirmLeaveForm: false,
      isLoading: false,
      customerData: {
        name: '',
        major: '',
        email: '',
        address: '',
        phone: '',
        is_active: false
      },
      customerRules: {
        name: [
          { required: true, message: 'нэрээ оруулна уу', trigger: 'blur' }
        ],
        major: [
          { required: true, message: 'Албан тушаалаа оруулна уу', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утас дугаараа оруулна уу', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Е-мэйл хаягаа оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Ажлын хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    getOneEmployee (id) {
      this.isLoading = true
      services.getOneEmployee(id).then(response => {
        if (response.status === 'success') {
          this.customerData = response.data
          this.isLoading = false
        }
      })
    },
    deleteEmployee (id) {
      this.isLoading = true
      services.deleteEmployee(id).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} id-тай хэрэглэгч устлаа`, 'success')
          this.isLoading = false
          this.$router.push({
            name: 'customerManagerList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${id} id-тай хэрэглэгч устгахад алдаа гарлаа`, 'error')
          this.isLoading = false
        }
      })
    },
    getUpdateEmployee (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          if (this.customerData.employee_id !== '') {
            this.isLoading = true
            services.getUpdateEmployee(this.customerData).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.isLoading = false
                this.$router.push({ name: 'customerManagerList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.isLoading = false
              }
            })
          }
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
